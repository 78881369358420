import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function IWouldHaveLeft() {
  return (
    <Layout>
      <Helmet title="I Would Have Left | Michael Weslander" />

      <h1>I Would Have Left</h1>
      <h2>Michael Weslander</h2>
      <h4>Key: E</h4>
      <h4>Tempo: 90</h4>
      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>I would have left, if you hadn’t stopped me</p>
          <p>Now I’m running late, this is exhausting</p>
        </Verse>

        <PreChorus>
          <p>If I’m ashamed, I’m helpless to change anyhow</p>
          <p>For I wouldn’t trade, a minute away even now</p>
        </PreChorus>

        <Verse>
          <p>I would have left, if you hadn’t walked out</p>
          <p>A pair of graduation week, high school dropouts</p>
        </Verse>

        <PreChorus>
          <p>In between the lines, my imagination ran</p>
          <p>A stranger to myself, a saboteur of my own plans</p>
        </PreChorus>

        <Chorus>
          <p>If only I had realized</p>
          <p>You were on my team</p>
          <p>The whole time</p>
          <p>(X3)</p>
        </Chorus>

        <Verse>
          <p>You might have stayed, if I had onlAAy</p>
          <p>Been able to get on out, of my own way</p>
        </Verse>
      </Columns>
    </Layout>
  );
}
